import React, { useEffect, useState } from 'react';
import Select from 'react-select'

export default function AutoComplete({register, defaultValue, ...props}) {
	const [selected, setSelected] = useState(defaultValue);

	useEffect(() => {
		setSelected(defaultValue);
	}, [defaultValue]);

	return <>
		<Select onChange={setSelected} {...props} value={selected} />
		<input {...props} ref={register} type="hidden" value={selected?selected.value:null}></input>
	</>
}