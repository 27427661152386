import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import phoneIcon from "../../../assets/images/icons/phone.png";
import { InputGroup } from 'react-bootstrap';
import "../form.scss";

export default function SchoolBasicInfo({ register, ...prop }) {

    return <div>
        <fieldset disabled={prop.isReadOnly}>
            <Form.Row>
                <Col xs={7}>
                    <Form.Group controlId="formBasicEmail" className={!prop.isReadOnly ? 'required' : null}>
                        <Form.Label className="control-label" >E-mailadres</Form.Label>
                        <Form.Control rules={{ maxLength: 255 }} ref={register} type="email" required placeholder="E-mail" defaultValue={prop ? prop.email : null} name='email' onChange={prop.checkEmail ? prop.checkEmail : null} />
                    </Form.Group>
                </Col>
                <Col xs={7}>
                    <Form.Group controlId="formFirstName" className={!prop.isReadOnly ? 'required' : null} >
                        <Form.Label className="control-label">Voornaam</Form.Label>
                        <Form.Control ref={register} type="text" placeholder="Voornaam" defaultValue={prop ? prop.first_name : null} required name='first_name' />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formLastName" className={!prop.isReadOnly ? 'required' : null}>
                        <Form.Label className="control-label">Achternaam</Form.Label>
                        <Form.Control ref={register} type="text" required placeholder="Achternaam" defaultValue={prop ? prop.last_name : null} name='last_name' />
                    </Form.Group>
                </Col>
            </Form.Row>

            <div className="profile-docent-personal-info">
                <Form.Row>
                    <Col>
                        <Form.Group className={!prop.isReadOnly && prop.userType === 'D' ? 'required' : null}>
                            <Form.Label className="control-label">Telefoonnummer</Form.Label>
                            <InputGroup>
                                {prop.telefoonnummer ? <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <a href={`tel:${prop.telefoonnummer ? prop.telefoonnummer : null}`}>
                                            <img height="20px" width="20px" src={phoneIcon} alt="phone" />
                                        </a>
                                    </InputGroup.Text>
                                </InputGroup.Prepend> : null}
                                <Form.Control ref={register} required={prop.userType === 'D' ? true : false} type="phone" placeholder="Telefoonnummer" defaultValue={prop ? prop.telefoonnummer : null} rules={{ maxLength: 20 }} name='telefoonnummer' />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formGeslacht" className={!prop.isReadOnly && prop.userType === 'D' ? 'required' : null}>
                            <Form.Label className="control-label">Geslacht</Form.Label>
                            <Form.Control ref={register} as="select" defaultValue={prop ? prop.geslacht : ""} required={prop.userType === 'D' ? true : false} name="geslacht">
                                <option disabled={true} value="">Kies...</option>
                                <option value="M">Man</option>
                                <option value="F">Vrouw</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Form.Row>

                <Form.Row>


                    <Form.Group as={Col} controlId="formGridAddress1" className={!prop.isReadOnly && prop.userType === 'D' ? 'required' : null}>
                        <Form.Label className="control-label">Straatnaam</Form.Label>
                        <Form.Control ref={register} placeholder="Prinsengracht" defaultValue={prop ? prop.straat : null} required={prop.userType === 'D'} name='straat' />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridStraatNum" className={!prop.isReadOnly && prop.userType === 'D' ? 'required' : null}>
                        <Form.Label className="control-label">Huisnummer</Form.Label>
                        <Form.Control ref={register} placeholder="701" defaultValue={prop ? prop.huisnummer : null} required={prop.userType === 'D'} name='huisnummer' maxLength="5" />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridStraatNum">
                        <Form.Label>Toevoegingen</Form.Label>
                        <Form.Control ref={register} placeholder="H" name='toevoegingen' defaultValue={prop ? prop.toevoegingen : null} maxLength="5" />
                    </Form.Group>

                    <Form.Group controlId="formGridZip" className={!prop.isReadOnly && prop.userType === 'D' ? 'required' : null}>
                        <Form.Label className="control-label">Postcode</Form.Label>
                        <Form.Control ref={register} defaultValue={prop ? prop.postcode : null} required={prop.userType === 'D'} placeholder="1017JV" maxLength="6" name='postcode' />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridCity" className={!prop.isReadOnly && prop.userType === 'D' ? 'required' : null}>
                        <Form.Label className="control-label">Woonplaats</Form.Label>
                        <Form.Control ref={register} placeholder="Amsterdam" defaultValue={prop ? prop.woonplaats : null} required={prop.userType === 'D'} name='woonplaats' />
                    </Form.Group>
                </Form.Row>
                {prop.userType === 'D' ? null : <Form.Group controlId="formContactDate">
                    <Form.Label>Laatste contact moment</Form.Label>
                    <Form.Control ref={register} type="date" placeholder="17-10-2000" name='laatste_contact' defaultValue={prop ? prop.laatste_contact : null} />
                </Form.Group>}
                <Form.Group controlId="formIBAN" className={!prop.isReadOnly && prop.userType === 'D' && prop.id_verwerkt !== "Verwerkt" ? 'required' : null}>
                    <Form.Label className="control-label">IBAN</Form.Label>
                    <Form.Control ref={register} type="text" placeholder="NL00INGB1234567890" defaultValue={prop ? prop.iban : null} readOnly={prop.isReadOnly ? true : prop.userType === 'D' && prop.id_verwerkt === "Verwerkt" ? true : false} required={prop.userType === 'D' && prop.id_verwerkt !== "Verwerkt"} name='iban' />
                    <Form.Text className="text-muted">
                        E-mail ons om je IBAN te wijzigen.
                    </Form.Text>
                </Form.Group>

            </div>
        </fieldset>
    </div >
}