import axios from 'axios';
import displayToast from './toasts';

async function renderScholenOptions() {
  let scholen;
  let scholenOption = <option>Kies...</option>;
  return getAllScholen().then(res => {
    scholen = res.data;
    for (let school in scholen) {
      scholenOption += <option value={scholen[school].id}>{scholen[school].name}</option>;
    }
    res = <>{scholenOption}</>;
    return (res);
  });
}

async function getAllScholen() {
  return axios.get(process.env.REACT_APP_API_URL + '/scholen/school/all', {
    withCredentials: true
  })
    .catch(function (error) {
      displayToast(`Server onbereikbaar. ${error})`);
    });
}

export {
  renderScholenOptions,
  getAllScholen
};