import {
    toast
} from 'react-toastify';

export default function displayToast(reason, type, history, redirect, autoclose) {

    if (type === 'success')
        toast.success(reason, {
            position: "bottom-center",
            autoClose: autoclose ? autoclose : 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
                textAlign: 'center'
            },
            onOpen: () => {
                if (history) {
                    if (redirect) history.push(redirect);
                    else history.go();
                }
            }
        });
    else
        toast.error(reason, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
                textAlign: 'center'
            }
        });
}