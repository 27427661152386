import './App.css';
import Navigation from "./components/navigation/Navigation";
import Login from "./components/login/Login";
import List from './components/docenten/List';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import {
  BrowserRouter as Router,
  Route, Switch
} from "react-router-dom";
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import ProfileDocent from './components/profiles/ProfileDocent';
import ProfileSchool from './components/profiles/ProfileSchool';
import Add from './components/docenten/AddDocent';
import AddSchool from './components/docenten/AddSchool';
import displayToast from "./components/helperFunctions/toasts";
import ChangePassword from './components/docenten/ChangePassword';
import AutoSignIn from './components/login/AutoSignIn';
import { getUserInfo } from "./components/helperFunctions/autoSignIn";
import { useSelector, useDispatch } from "react-redux";
import { updateUserInfo } from "./components/redux/userSlice";
import ListTitle from "./components/navigation/ListTitle";
import ScrollToTop from './components/navigation/ScrollToTop';
import FormTabs from './components/form/FormTabs';
import Schedule from './components/scheduler/Schedule';
import FinancialOverview from './components/financial/financialOverview';

function App() {
  const [hamburger, setHamburger] = useState(false);
  const userInfo = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
  axios.defaults.xsrfCookieName = "csrftoken";

  let regions = [
    undefined,
    "Amsterdam",
    "Friesland",
    "Groningen",
    "Gelderland",
    "Limburg",
    "Midden-Nederland",
    "Noord-Brabant",
    "Noord-Holland",
    "Zuid-Holland",
    "Drenthe",
    "Overijssel",
    "Flevoland",
    "Utrecht",
    "Zeeland",
  ]

  useEffect(() => {
    dispatch(updateUserInfo("loading"));
    if (!userInfo) {
      getUserInfo().then(res => {
        if (res && res.status === 200) {
          dispatch(updateUserInfo(res.data));
        } else {
          dispatch(updateUserInfo(false));
        }
      })
    }
  }, [])

  // function isEmpty(object) {
  //   for (var i in object) return false;
  //   return true;
  // }

  let defaultTeacherColumns = [
    { name: 'user__first_name', title: 'Voornaam' },
    { name: 'user__last_name', title: 'Achternaam' },
    { name: 'telefoonnummer', title: 'Telefoonnummer' },
    { name: 'user__email', title: 'Email' },
    { name: 'woonplaats', title: 'Woonplaats' },
    { name: 'laatste_contact', getCellValue: row => row.laatste_contact ? row.laatste_contact : "Geen contact gehad.", title: 'Laatste contact' },
    { name: 'Status', getCellValue: row => (row.vog && row.contract_signed_bms && row.contract && row.vog_verstuurd ? "Klaar" : row.contract && row.contract_signed_bms && row.vog_verstuurd ? "Afwachten VOG" : row.contract && row.contract_signed_bms ? "VOG versturen" : row.contract ? "Contract ondertekenen" : "Contract opstellen"), title: 'Status' },
    { name: 'gekoppelde_scholen', getCellValue: row => (row.gekoppelde_scholen ? row.gekoppelde_scholen : "Geen gekoppelde scholen."), title: 'Scholen' },
  ]
  let defaultTeacherColumnExts = [
    { columnName: 'Status', filteringEnabled: false, sortingEnabled: false }
  ]

  let defaultLessonColumns = [
    { name: 'school__name', title: 'School' },
    { name: 'title', title: 'Titel' },
    { name: 'docenten__user__first_name', title: 'Voornaam docent' },
    { name: 'docenten__user__last_name', title: 'Achternaam docent' },
    { name: 'startDate__date', title: 'Datum' },
    { name: 'startDate__time', title: 'Starttijd' },
    { name: 'endDate__time', title: 'Eindtijd' },
    { name: 'eventDuration', title: 'Duur', getCellValue: row => Math.floor(row.eventDuration / 3600) + ":" + Math.floor(row.eventDuration / 60 % 60) },
  ];
  let defaultLessonColumnExts = [
    { columnName: 'eventDuration', filteringEnabled: false, sortingEnabled: false },
  ]

  let tabContents = [<List getData={getAllDocentenVOG} include="vog_verstuurd" listName="docent" column={defaultTeacherColumns} columnExtensions={defaultTeacherColumnExts}
    sorting_column="first_name" sorting_direction="asc"
  />, <List getData={getAllDocentenVOG} include="vog_bewijs" listName="docent" column={defaultTeacherColumns} columnExtensions={defaultTeacherColumnExts}
    sorting_column="first_name" sorting_direction="asc"
  />, <List getData={getAllDocentenVOG} include="vog" listName="docent" column={defaultTeacherColumns} columnExtensions={defaultTeacherColumnExts}
    sorting_column="first_name" sorting_direction="asc"
  />]
  let tabTitles = ["Aanvragen", "Ontvangen betalingsbewijs", "Ontvangen VOG"]

  function getAllDocentenBMS(limit, offset, filterprops) {
    filterprops = { ...filterprops, bms: 'True' };
    return axios.get(`${process.env.REACT_APP_API_URL}/scholen/docent/all?limit=${limit}&offset=${offset}`, { withCredentials: true, params: filterprops })
      .catch(function (error) {
        displayToast(`Server onbereikbaar. ${error})`);
      });
  }
  function getAllDocentenVOG(limit, offset, filterprops) {
    // vog aanvragen belangrijk
    //  3 tabbladen met verschillende groepringen van status.
    filterprops = { ...filterprops, bms: 'True' };
    return axios.get(`${process.env.REACT_APP_API_URL}/scholen/docent/all?limit=${limit}&offset=${offset}`, { withCredentials: true, params: filterprops })
      .catch(function (error) {
        displayToast(`Server onbereikbaar. ${error})`);
      });
  }
  function getAllDocenten(limit, offset, filterprops) {
    return axios.get(`${process.env.REACT_APP_API_URL}/scholen/docent/all?limit=${limit}&offset=${offset}`, { withCredentials: true, params: filterprops })
      .catch(function (error) {
        displayToast(`Server onbereikbaar. ${error})`);
      });
  }

  function getAllScholen(limit, offset, filterprops) {
    return axios.get(`${process.env.REACT_APP_API_URL}/scholen/school/all?limit=${limit}&offset=${offset}`, { withCredentials: true, params: filterprops })
      .catch(function (error) {
        displayToast(`Server onbereikbaar. ${error})`);
      });
  }

  function getEventsPack(typeparam) {
    return (limit, offset, filterprops) => {
      filterprops = { allDay: "False", ...filterprops };
      return axios.get(`${process.env.REACT_APP_API_URL}/scholen/schedule/events/list/${typeparam}?limit=${limit}&offset=${offset}`, { withCredentials: true, params: filterprops })
        .catch(function (error) {
          displayToast(`Server onbereikbaar. ${error}`);
        })
    }
  }

  return (
    <Router>
      <div id="app-content">
        <Navigation hamburger={hamburger} setHamburger={setHamburger} />
        <div onClick={() => { if (hamburger) setHamburger(false) }}>
          <ScrollToTop />
          <Route exact path='/'>
            <Login />
          </Route>


          <Switch>
            <Route exact path='/login'>
              <Login />
            </Route>

            <Route exact path='/autosignin'>
              <AutoSignIn />
            </Route>

            <PrivateRoute exact path='/docent/:pathParam'><ProfileDocent coordinator={userInfo && userInfo !== "loading" ? userInfo.is_coordinator : null} getScholen={getAllScholen} /></PrivateRoute>

            <PrivateRoute exact path='/school/:pathParam'><ProfileSchool getDocenten={getAllDocentenBMS} getScholen={getAllScholen} /></PrivateRoute>

            <PrivateRoute exact path='/change-password'><ChangePassword /></PrivateRoute>

            <PrivateRoute exact path='/me'><ProfileDocent /></PrivateRoute>

            <PrivateRoute exact path='/docenten-bms'><ListTitle title={"Docenten"} redirect={"/docenten/add"} /><List getData={getAllDocentenBMS} listName="docent" column={defaultTeacherColumns} columnExtensions={defaultTeacherColumnExts}
              sorting_column="first_name" sorting_direction="asc"

            />

            </PrivateRoute>

            <PrivateRoute exact path='/docenten'> <ListTitle title={"Docenten"} redirect={"/docenten/add"} /><List getData={getAllDocenten} listName="docent" column={defaultTeacherColumns} columnExtensions={defaultTeacherColumnExts}
              sorting_column="first_name" sorting_direction="asc"

            />
            </PrivateRoute>

            <PrivateRoute exact path='/docenten-vog'>
              <ListTitle title={"Docenten - VOG"} redirect={"/docenten/add"} />
              <div className="docenten-vog"><FormTabs no_force={true} tabTitle={tabTitles} content={tabContents} /></div>
            </PrivateRoute>

            <PrivateRoute exact path='/lessen/gegeven'>
              <ListTitle title={"Gegeven lessen"} />
              <List getRowId={row => "Gegeven" + row.docenten__id + "_" + row.id + "_" + row.startDate} getData={getEventsPack("past")} fields={defaultLessonColumns.map(x => x.name)} column={defaultLessonColumns} sorting_column="startDate" sorting_direction="desc" columnExtensions={defaultLessonColumnExts} />
            </PrivateRoute>
            <PrivateRoute exact path='/lessen/actief'>
              <ListTitle title={"Actieve lessen"} />
              <List getRowId={row => "Actieve" + row.docenten__id + "_" + row.id + "_" + row.startDate} getData={getEventsPack("ongoing")} fields={defaultLessonColumns.map(x => x.name)} column={defaultLessonColumns} sorting_column="startDate" sorting_direction="asc" columnExtensions={defaultLessonColumnExts} />
            </PrivateRoute>
            <PrivateRoute exact path='/lessen/gepland'>
              <ListTitle title={"Geplande lessen"} />
              <List getRowId={row => "Geplande" + row.docenten__id + "_" + row.id + "_" + row.startDate} getData={getEventsPack("future")} fields={defaultLessonColumns.map(x => x.name)} column={defaultLessonColumns} sorting_column="startDate" sorting_direction="asc" columnExtensions={defaultLessonColumnExts} />
            </PrivateRoute>

            <PrivateRoute exact path='/scholen'>  <ListTitle title={"Scholen"} redirect={"/scholen/add"} />  <List getData={getAllScholen} listName="school" column={[
              { name: 'name', title: 'Naam' },
              { name: 'region', getCellValue: row => (regions[row.region] ? regions[row.region] : "Onbekend"), title: 'Regio' },
              {
                name: 'school_type', getCellValue: row => (row.school_type
                  === 1 ? "Basisschool" : row.school_type === 2 ? "Middelbare school" : row.school_type === 3 ? "Basis school NT2" : row.school_type === 4 ? "Middelbare school NT2" : "Onbekend"), title: 'Type'
              },
              { name: 'start_date', title: 'Startdatum' },
            ]}
              sorting_column="name" sorting_direction="asc"
            /></PrivateRoute>

            <PrivateRoute exact path='/docenten/add'>  <Add /> </PrivateRoute>
            <PrivateRoute exact path='/financial'>  <FinancialOverview /> </PrivateRoute>

            <PrivateRoute exact path='/scholen/add'><div className="school-container"><AddSchool /></div></PrivateRoute>
          </Switch>
        </div>
      </div>

      <ToastContainer />
    </Router>
  );
}

export default App;
