import React from 'react';
import Form from 'react-bootstrap/Form';

export default function SchoolPraktischeInfo(prop) {
    return <div>
        <fieldset disabled={!prop.editable || prop.isReadOnly}>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Docenten aanmeldpunt</Form.Label>
                <Form.Control as="textarea" rows={3} defaultValue={prop ? prop.aanmeldpunt_docenten : null} name='aanmeldpunt_docenten' />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Parkeerinformatie</Form.Label>
                <Form.Control as="textarea" rows={1} defaultValue={prop ? prop.parkeerplek : null} name='parkeerplek' />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Corona maatregelen</Form.Label>
                <Form.Control as="textarea" rows={2} defaultValue={prop ? prop.corona_maatregelen : null} name='corona_maatregelen' />
            </Form.Group>
        </fieldset>
    </div >
}