import { useState } from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import axios from 'axios';
import { FaSignOutAlt } from 'react-icons/fa';
import { HiChevronDown } from 'react-icons/hi';
import { useHistory } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import logo from "../../assets/images/logos/BijlesMeesterLogo.png"
import "./Navigation.scss";
import ProfileBar from "./ProfileBar";
import displayToast from '../helperFunctions/toasts';
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "../redux/userSlice";
import { Link } from 'react-router-dom';
import cross from '../../assets/images/icons/cross.svg';

function Welcome(props) {
  const [dropDownDocent, setdropDownDocent] = useState(true);
  const [dropDownSchool, setdropDownSchool] = useState(true);
  const [dropDownLessen, setdropDownLessen] = useState(true);
  const [dropDownFinancial, setDropDownFinancial] = useState(true);
  const userInfo = useSelector((state) => state.user.user);
  const history = useHistory();
  const dispatch = useDispatch();


  async function logout(event) {
    event.preventDefault();
    await axios.get(process.env.REACT_APP_API_URL + '/scholen/logout', { withCredentials: true }).then(function (response) {
      if (response.status === 200) {
        displayToast('Log-out succesvol, redirecting naar log-in!', 'success', history, '/login');
        dispatch(updateUserInfo(null));
      }
      else
        displayToast(`Uitloggen afgewezen door server. Statuscode: ${response.status}`)
    })
      .catch(function (error) {
        displayToast(`Server onbereikbaar. ${error})`);
      });
  }
  return (
    <div className="sidenav--wrapper">
      <div id="mySidenav" className={`sidenav ${props.hamburger ? "nav-width" : null}`}>
        <div className={`close-btn-burger ${!props.hamburger ? "container" : "change"}`} onClick={() => { props.setHamburger(!props.hamburger) }}>
          <img className="close-btn-cross" src={cross} alt="cross" />
        </div>

        <Link to={userInfo && userInfo.user_type !== 'D' ? "/docenten-bms" : "/me"}>Home</Link>
        {userInfo && userInfo.user_type !== 'D' ? <><Nav.Link onClick={() => { setdropDownDocent(!dropDownDocent) }}>Docenten<HiChevronDown /></Nav.Link>
          <div className="dropdown-container" style={{ display: (dropDownDocent ? "none" : "block") }} id="dropdown">
            <Link to="/docenten-bms">BMS</Link>
            <Link to="/docenten">Alle</Link>
            <Link to="/docenten-vog">VOG</Link>
          </div>

          <Nav.Link onClick={() => { setdropDownSchool(!dropDownSchool) }}>Scholen<HiChevronDown /></Nav.Link>
          <div className="dropdown-container" style={{ display: (dropDownSchool ? "none" : "block") }} id="dropdown">
            <Link to="/scholen">Scholen</Link>
          </div>

          <Nav.Link onClick={() => { setdropDownLessen(!dropDownLessen) }}>Lessen<HiChevronDown /></Nav.Link>
          <div className="dropdown-container" style={{ display: (dropDownLessen ? "none" : "block") }} id="dropdown">
            <Link to="/lessen/gegeven">Gegeven lessen</Link>
            <Link to="/lessen/actief">Actieve lessen</Link>
            <Link to="/lessen/gepland">Geplande lessen</Link>
          </div>

          {userInfo.user_type === 'A' ? <><Nav.Link onClick={() => { setDropDownFinancial(!dropDownFinancial) }}>Financials<HiChevronDown /></Nav.Link>
            <div className="dropdown-container" style={{ display: (dropDownFinancial ? "none" : "block") }} id="dropdown">
              <Link to="/financial">Excel sheets</Link>
            </div> </> : null}

        </> : null}

        <div className="sign-out" onClick={logout}>
          <FaSignOutAlt /><p>Sign out</p>

        </div>
      </div>
      <Navbar onClick={() => { if (props.hamburger) props.setHamburger(false) }} className={`bmo-red`} bg="dark" variant="dark" id="bmo-navbar">
        <div className={!props.hamburger ? "container" : "change"} onClick={() => { props.setHamburger(!props.hamburger) }}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
        <img src={logo} alt="BijlesMeester logo" className="bmo-logo" />
        <Link to={userInfo && userInfo.user_type !== 'D' ? "/docenten-bms" : "/me"}><Navbar.Brand>De Bijlesmeester Scholen</Navbar.Brand></Link>
        <Nav className="mr-auto">

        </Nav>
        <ProfileBar />
      </Navbar>
    </div>
  )
}

export default Welcome;