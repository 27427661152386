import React from 'react';
import Form from 'react-bootstrap/Form';

export default function SchoolPlanningInfo(prop) {
    return <div>
        <fieldset disabled={!prop.editable || prop.isReadOnly}>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Huidige situatie</Form.Label>
                <Form.Control as="textarea" rows={3} defaultValue={prop ? prop.huidige_situatie : null} name='huidige_situatie' />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Gewenste situatie</Form.Label>
                <Form.Control as="textarea" rows={3} defaultValue={prop ? prop.gewenste_situatie : null} name='gewenste_situatie' />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Kernwaarden</Form.Label>
                <Form.Control as="textarea" rows={1} defaultValue={prop ? prop.kern_waarden : null} name='kern_waarden' />
            </Form.Group>
        </fieldset>
    </div >
}