import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "../../assets/styles/tab.scss";

class FormTabs extends Component {

    render(props) {
        const tabStyle = "tab-style--1";
        return (
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs forceRenderTabPanel={this.props.no_force ? false : true}>
                                    <TabList className={`${tabStyle}`}>
                                        {this.props.tabTitle.filter(Boolean).map((value, key) => {
                                            return (<Tab key={key}>{value}</Tab>
                                            )
                                        })}
                                    </TabList>
                                    {this.props.content.filter(Boolean).map((value, key) => { return (<TabPanel key={key}>{value}</TabPanel>) })}

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default FormTabs;