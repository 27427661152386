const axios = require('axios');

// function to convert json to csv seperated by commas.
function jsonToCsv(array, teacher) {
    const items = array;
    const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    const header_items = Object.keys(items[0]);
    let header;
    if(teacher)
        header = ["Voornaam", "IBAN","Achternaam","Docent ID"]
    else
        header = [ "School ID","School"];
    header.push("Uren totaal","Lesdagen totaal","Lessen totaal");
    const csv = "data:text/csv;charset=utf-8," + [
        header.join(';'), // header row first
        ...items.map(row => header_items.map(fieldName => fieldName != "rRule" ? JSON.stringify(row[fieldName], replacer) : null).join(';'))
    ].join('\r\n')
    var encodedUri = encodeURI(csv);
    return encodedUri;
}

// Get a set of the years in the schedule
export function getAllScheduleYears(arr) {
    let yearSet = new Set();
    console.log(arr);
    arr.forEach(item => item.startDate ? yearSet.add(item.startDate.split("-")[0]) : null);
    arr = Array.from(yearSet);
    return arr;
}

// Get url and make request to calculate total hours
// Use teacher property to indicate whether we will use filter view or not
export async function calculateTotalOf(url, teacher) {
    return axios.get(url, {
        withCredentials: true
    }).then((res) => {
        if (!res.data.length) { // if res is empty, don't attempt to iterate over stuff and immediately return this.
            return {
                "totalHours": 0,
                "years": [],
                "uri": null,
            }
        }
        let idSet = new Set();
        let arr;
        if (teacher) {
            res.data.forEach(item => idSet.add(item.docenten__id));
            arr = filterListPerProperty(res.data, "docenten__id"); // Filter res.data into an array with unique teachers
        } else {
            res.data.forEach(item => idSet.add(item.school__id));
            arr = arr = filterListPerProperty(res.data, "school__id"); // Filter res.data into an array with unique teachers
        }
        arr.forEach(item => {
            delete item.eventDuration; // removing unnecessary and empty columns.
            delete item.exDate;
            delete item.rRule;
            delete item.id;
            if (teacher){
                delete item.school__id;
                delete item.school__name;
                
            }else{
                delete item.docenten__user__last_name;
                delete item.docenten__user__first_name;
                delete item.docenten__iban;
                delete item.docenten__id;
                delete item.id;
            }
            item.totalHours = calculateTotalHours(listFilter(res.data, teacher ? item.docenten__id : item.school__id, teacher));
            item.totalDays = calculateTotalDays(listFilter(res.data, teacher ? item.docenten__id : item.school__id, teacher));
            item.totalClasses = listFilter(res.data, teacher ? item.docenten__id : item.school__id, teacher).length;
        }); // Populate unique teacher array with corresponding total hours

        let totalHours = calculateTotalHours(res.data);
        arr.forEach(item => {
            delete item.startDate;
            delete item.endDate
        });
        return {
            "totalHours": totalHours,
            "years": getAllScheduleYears(res.data),
            "uri": jsonToCsv(arr, teacher),
        };
    });
}

// Filter array to a teacher set based on teacher id
function filterListPerProperty(arr, property) {
    return arr.filter((v, i, a) => a.findIndex(t => (t[property] === v[property])) === i);
}

// Function to calculate total hours
function calculateTotalHours(arr) {

    let totalHours = 0;

    arr.forEach(item => {
        if (item.startDate && item.endDate)
            totalHours += convertDateTimeToTotal(item.startDate, item.endDate);
    });
    return totalHours;
}

// Function that calculates total days of having worked.
function calculateTotalDays(arr) {
    let dateSet = new Set();
    arr.forEach(item => {
        if (item.startDate && item.endDate) {
            let date = new Date(item.startDate);
            date = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
            dateSet.add(date);
        }
    });
    return dateSet.size;
}

// function to filter array on same id
// If you'd like to extend this further than school_id and docent_id:
// Please remove if statement and pass property of array to filter on instead.
function listFilter(arr, id, teacher) {
    return arr.filter(
        function (arr) {
            if (teacher)
                return arr.docenten__id == id
            else
                return arr.school__id == id
        }
    );
}



// Calculate total difference between two date time objects.
function convertDateTimeToTotal(startDateTime, endDateTime) {
    startDateTime = new Date(startDateTime);
    endDateTime = new Date(endDateTime);
    var difference = endDateTime.getTime() - startDateTime.getTime();
    return msToHours(difference);
}

// Convert ms to hours.
function msToHours(ms) {
    let hours = (ms / (1000 * 60 * 60));
    return hours;
}