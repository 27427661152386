import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Row } from 'react-bootstrap';

export default function SchoolPlanningInfo(prop) {
    return <div>
        <fieldset disabled={!prop.editable || prop.isReadOnly}>
            { prop.editable ? <>
            <Row>
                <Col>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Looptijd</Form.Label>
                        <Form.Control as="textarea" rows={2} defaultValue={prop ? prop.looptijd : null} name='looptijd' />
                        <Form.Text className="text-muted">
                        Looptijd in offerte uren
                    </Form.Text>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formLastName">
                        <Form.Label>Startdatum</Form.Label>
                        <Form.Control type="date" placeholder="17-10-2000" defaultValue={prop ? prop.start_date : null} name='start_date' />
                    </Form.Group>
                </Col>

            </Row>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Lesduur</Form.Label>
                <Form.Control as="textarea" rows={2} defaultValue={prop ? prop.lesduur : null} name='lesduur' />
            </Form.Group>
            </> : null }
            <Row>
                <Col>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Lesvorm</Form.Label>
                        <Form.Control as="textarea" rows={2} defaultValue={prop ? prop.les_vorm : null} name='les_vorm' />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Materiaal</Form.Label>
                        <Form.Control as="textarea" rows={2} defaultValue={prop ? prop.materiaal : null} name='materiaal' />
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Kwalificatiespecificatie docenten</Form.Label>
                <Form.Control as="textarea" rows={2} defaultValue={prop ? prop.kwalificatie : null} name="kwalificatie" />
            </Form.Group>

            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label><strong>Groepjes bestand upload</strong></Form.Label>
                        <Form.File id="exampleFormControlFile1" defaultValue={prop ? prop.groepjes : null} name="groepjes" />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label><strong>Leerlingenschets upload</strong></Form.Label>
                        <Form.File id="exampleFormControlFile1" defaultValue={prop ? prop.leerlingen_schets : null} name="leerlingen_schets" />
                    </Form.Group>
                </Col>
            </Row>
        </fieldset>
    </div >
}
