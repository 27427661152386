import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import displayToast from '../helperFunctions/toasts';
import { formatDateTime } from '../helperFunctions/general';
import Notities from '../profiles/notesForm';
import SchoolBasicInfo from '../form/school/SchoolBasicInfo';
import SchoolAchtergrondInfo from '../form/school/SchoolAchtergrondInfo';
import SchoolPraktischeInfo from '../form/school/SchoolPraktischeInfo';
import SchoolPlanningInfo from '../form/school/SchoolPlanningInfo';
import SchoolContactAdd from '../form/school/SchoolContactAdd';
import SchoolCoordinatorAdd from '../form/school/SchoolCoordinatorAdd';
import SchoolDocenten from '../form/school/SchoolDocenten';
import FormTabs from '../form/FormTabs';
import { handleInvalidForm } from '../helperFunctions/general';
import EditButton from '../form/EditButton';
import { useSelector } from "react-redux";
import Schedule from '../scheduler/Schedule';

export default function AddSchool(props) {
    const history = useHistory();
    let [prop, setProps] = useState();
    let [notesForOverview, setNotes] = useState();
    let [input, setInput] = useState("");
    var parts = window.location.pathname.split('/');
    var schoolID = parts[parts.length - 1];
    const userInfo = useSelector((state) => state.user.user);
    const [isReadOnly, setReadOnly] = useState(true);
    const isAdmin = userInfo.user_type === 'A';
    var tabTitles = [], tabContents = [];
    const [saveButton, setSaveButton] = useState(false);

    useEffect(() => { getSchool() }, []);
    async function getSchool() {
        await axios.get((isAdmin ? `${process.env.REACT_APP_API_URL}/scholen/school/${schoolID}` : `${process.env.REACT_APP_API_URL}/scholen/docent/school/${schoolID}`), { withCredentials: true })
            .then(function (response) {
                prop = setProps(response.data)
                if (isAdmin) getNotes();
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function editProfile(event) {
        setSaveButton(true);
        event.preventDefault();
        let form = new FormData(event.target);
        let docentSchoolLink = [];
        // if (form.get("gekoppelde_contacten[]")) {
        for (let i = 0; i < document.getElementsByName("gekoppelde_contacten[]").length; i++) {
            let element = {
                "id": document.getElementsByName("gekoppelde_contacten[]")[i].value
            };
            if (document.getElementsByName("gekoppelde_contacten[]")[i].childNodes[0].getAttribute("data-key")) element["koppel_id"] = document.getElementsByName("gekoppelde_contacten[]")[i].childNodes[0].getAttribute("data-key")
            docentSchoolLink.push(element);
        }
        // }
        form.delete("gekoppelde_contacten[]");

        let cns = document.getElementsByName("contactnumber[]");
        for (let i = 0; i < cns.length; i++) {
            let pidx = cns[i].value;

            let phonenums = [], emails = [];
            document.getElementsByName(`mail_addrs[${pidx}][]`).forEach((x) => emails.push(x.value));
            document.getElementsByName(`phone_numbers[${pidx}][]`).forEach((x) => phonenums.push(x.value));

            docentSchoolLink.push({
                id: form.get(`contactID[${pidx}]`),
                geslacht: form.get(`geslacht[${pidx}]`),
                first_name: form.get(`first_name[${pidx}]`),
                last_name: form.get(`last_name[${pidx}]`),
                functie: form.get(`functie[${pidx}]`),
                mail_addrs: emails,
                phone_numbers: phonenums,
            });
            form.delete(`geslacht[${pidx}]`);
            form.delete(`first_name[${pidx}]`);
            form.delete(`last_name[${pidx}]`);
            form.delete(`functie[${pidx}]`);
            form.delete(`mail_addrs[${pidx}][]`);
            form.delete(`phone_numbers[${pidx}][]`);
        }
        form.delete("contactnumber[]");

        form.append("gekoppelde_contacten", JSON.stringify(docentSchoolLink));

        let coords = [];
        // if (form.get("gekoppelde_coordinators[]")) {
        for (let i = 0; i < document.getElementsByName("gekoppelde_coordinators[]").length; i++) {
            coords.push({
                "id": document.getElementsByName("gekoppelde_coordinators[]")[i].value
            })
        }
        form.append("gekoppelde_coordinators", JSON.stringify(coords));
        // }
        form.delete("gekoppelde_coordinators[]");

        await axios.post(`${process.env.REACT_APP_API_URL}/scholen/school/edit/${schoolID}`, form, { withCredentials: true }).then(function (response) {
            if (response.status === 200)
                displayToast('School gewijzigd, reloading page!', 'success', history);
            else
                displayToast(`Upload afgewezen door server. Statuscode: ${response.status}`)
        }).catch((error, response) => {
            let errors = JSON.stringify(error.response.data);
            displayToast(`${errors}\n Server onbereikbaar. ${error})`);
        }).finally(() => {
            setSaveButton(false);
        })
    }

    async function getNotes() {
        await axios.get(`${process.env.REACT_APP_API_URL}/scholen/school/${schoolID}/notes`, { withCredentials: true })
            .then(function (response) {
                setNotes(response.data.map((x) => { return `[${formatDateTime(new Date(x.time), "Y-M-D h:m")} - ${x.creator_first_name} ${x.creator_last_name}] ${x.note}` }).join("\n"));
                let ta = document.getElementById("allnotes");
                ta.scrollTop = ta.scrollHeight;
            }).catch((error, response) => {
                let errors = JSON.stringify(error.response.data);
                displayToast(`${errors}\n Server onbereikbaar. ${error})`);
            });
    }


    async function updateNotes(event) {
        event.preventDefault();
        let form = new FormData(event.target);
        await axios.post(`${process.env.REACT_APP_API_URL}/scholen/school/${schoolID}/notes`, form, { withCredentials: true }).then(function (response) {
            if (response.status === 200) {
                setInput("");
                getNotes();
            } else
                displayToast(`Upload afgewezen door server. Statuscode: ${response.status}`)
        }).catch((error, response) => {
            let errors = JSON.stringify(error.response.data);
            displayToast(`${errors}\n Server onbereikbaar. ${error})`);
        });
    }

    if (prop) {

        var allTabs = {
            "Algemene informatie":
                <SchoolBasicInfo isReadOnly={isReadOnly} editable={isAdmin} name={prop.name} start_date={prop.start_date} school_type={prop.school_type} region={prop.region} telefoonnummer={prop.telefoonnummer} telefoonnummer2={prop.telefoonnummer2} straat={prop.straat} huisnummer={prop.huisnummer} toevoegingen={prop.toevoegingen} postcode={prop.postcode} woonplaats={prop.woonplaats} website={prop.website} />,
            "Contactpersonen":
                <SchoolContactAdd isReadOnly={isReadOnly} editable={isAdmin} gekoppelde_contacten={prop.all_contacts} />,
            "Praktische informatie":
                <SchoolPraktischeInfo isReadOnly={isReadOnly} editable={isAdmin} aanmeldpunt_docenten={prop.aanmeldpunt_docenten} parkeerplek={prop.parkeerplek} corona_maatregelen={prop.corona_maatregelen} />,
            "Situatie":
                <SchoolAchtergrondInfo isReadOnly={isReadOnly} editable={isAdmin} huidige_situatie={prop.huidige_situatie} gewenste_situatie={prop.gewenste_situatie} kern_waarden={prop.kern_waarden} />,
            "Planning":
                <SchoolPlanningInfo isReadOnly={isReadOnly} editable={isAdmin} looptijd={prop.looptijd} lesduur={prop.lesduur} groepjes={prop.groepjes} leerlingen_schets={prop.leerlingen_schets} les_vorm={prop.les_vorm} materiaal={prop.materiaal} kwalificatie={prop.kwalificatie} />,
            "Coördinatoren":
                <SchoolCoordinatorAdd isReadOnly={isReadOnly} editable={isAdmin} coordinators={prop.coordinators} />,
            "Docenten":
                <SchoolDocenten isAdmin={isAdmin} all_docenten={prop.all_docenten} />,
            "Rooster":
                <Schedule getDocenten={isAdmin ? props.getDocenten : null} getScholen={props.getScholen} schoolID={schoolID} userType={userInfo.user_type} />

        }
        if (!isAdmin) {
            delete allTabs["Contactgegevens"];
        }

        for (let key in allTabs) {
            tabTitles.push(key);
            tabContents.push(allTabs[key]);
        }
    }

    return <div className="profile-wrapper">
        {!prop ? <div className="profile-loader"><ClipLoader size={150} color={"#e02f2f"} loading={true} /> </div> :
            <Container>
                {isAdmin ? <EditButton isReadOnly={isReadOnly} setReadOnly={setReadOnly} /> : null}
                <h1 className="text-center">{prop.name}</h1>
                {isAdmin ? <Notities updateNotes={updateNotes} notesForOverview={notesForOverview} input={input} setInput={setInput} /> : null}
                <Form onSubmit={editProfile} onInvalid={handleInvalidForm} onKeyDown={(e) => {
                    if (e.nativeEvent.key === "Enter" && e.target.type !== "textarea") {
                        e.preventDefault();
                        const form = e.target.form;
                        const index = Array.prototype.indexOf.call(form, e.target);
                        form.elements[index + 1].focus();
                    }
                }}>
                    <div>
                        <FormTabs tabTitle={tabTitles}
                            content={tabContents} />
                        {isAdmin && !isReadOnly ? <>
                            <Button type="submit" className="mb-2 mt-2 ml-4 px-4" disabled={saveButton}>
                                Opslaan
                            </Button>
                            <Button type="reset" className="mb-2 mt-2 ml-4 px-3" variant="secondary" onClick={() => setReadOnly(true)} disabled={saveButton}>
                                Annuleren
                            </Button>
                        </> : null}
                    </div>
                </Form>
            </Container>
        }
    </div >
}
