import React, { useState, useEffect } from 'react';
import axios from 'axios';
import displayToast from '../helperFunctions/toasts';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Subject from '@material-ui/icons/Subject';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import {
    Scheduler,
    WeekView,
    AllDayPanel,
    Toolbar,
    DateNavigator,
    Appointments,
    AppointmentTooltip,
    AppointmentForm,
    DragDropProvider,
    EditRecurrenceMenu,
    TodayButton,
    ViewSwitcher,
    MonthView,
    DayView,
    Resources,
    ConfirmationDialog,
} from '@devexpress/dx-react-scheduler-material-ui';
import SweetAlert from 'react-bootstrap-sweetalert';
import "./Schedule.scss"
import Row from 'react-bootstrap/Row';

const Appointment = ({
  children, style, ...restProps
}) => (
  <Appointments.Appointment
    {...restProps}
    style={{
      ...style,
      ...(restProps.data.late_cancel ? {'textDecoration':'line-through 2px #AAAAAA', 'backgroundColor':'#AAAAAA'} : {})
    }}
  >
    {children}
  </Appointments.Appointment>
);

export default function Schedule(props) {
    const [addedAppointment, setAddedAppointment] = useState({});
    const [appointmentChanges, setAppointmentChanges] = useState({});
    const [editingAppointment, setEditingAppointment] = useState(undefined);
    const [isShiftPressed, setShiftPressed] = useState(false);
    const [appointmentData, setAppointmentData] = useState([]);
    const [docenten, setDocenten] = useState([]);
    const [school, setSchool] = useState([]);
    let [popup, setPopup] = useState(null);
    let confirmDelete = false;
    let deductDelete;
    /*
    GOAL:
    Make it so that scheduled classes can be identified as cancelled and deduct it from their total ticketed hours or not if it was cancelled on time.
    FIND A WAY TO DO:
    remove all-day text and replace with cancel appointment while in edit view.
    if this checkbox is checked for an appointment, show pop-up to ask whether it is going to refunded or not.
    ensure that all-day functionality goes away.
    */

    const scheduleUrl = process.env.REACT_APP_API_URL + "/scholen/schedule/";

    const SHIFT_KEY = 16;
    const allowDrag = ({ id }) => (!props.readOnly && props.userType === 'A');

    const resources = props.schoolID ? [
        {
            fieldName: "docenten",
            title: "Docenten",
            allowMultiple: true,
            instances: docenten
        }] : [{
            fieldName: "school",
            title: "School",
            instances: school
        }]

    const content = ({ appointmentData, ...restprops }) => (
        <AppointmentTooltip.Content {...restprops} appointmentData={appointmentData}>
            <Grid container >
                <Grid item xs={2} style={{textAlign: 'center'}}>
                    <Subject />
                </Grid>
                <Grid item xs={10}>
                    <span>{appointmentData.notes}</span>
                </Grid>
            </Grid>
        </AppointmentTooltip.Content>
    )


    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('keyup', onKeyUp);
        if (!props.schoolID)
            getScholen();
        else
            getDocenten();
        getAppointments();
    }, [props.schoolID])

    function onKeyDown(event) {
        if (event.keyCode === SHIFT_KEY) {
            setShiftPressed(true);
        }
    }

    function onKeyUp(event) {
        if (event.keyCode === SHIFT_KEY) {
            setShiftPressed(false);
        }
    }

    async function getDocenten() {
        // gekoppelde_scholen__school: schid
        if (props.getDocenten) { props.getDocenten(0, 0, props.schoolID ? { "koppel_scholen__active": true, "koppel_scholen__school_id": props.schoolID } : null).then((res) => { setDocenten(res.data.map(el => ({ ...el, text: (el.user__first_name + " " + el.user__last_name) }))) }); }
    }

    async function getScholen() {
        // pass props: docenten_link__docent: docid
        if (props.getScholen) { props.getScholen(0, 0).then((res) => { setSchool(res.data.map(el => ({ ...el, text: el.name }))) }) }
    }

    function displayErrs(error) {
        if (error?.response?.data?.non_field_errors?.includes("Overlap with existing event")) {
            displayToast("Kan de les niet inplannen, deze docent heeft al een andere les op dit tijdstip.");
            return;
        }

        displayToast("Kon de veranderingen niet opslaan, probeer het later opnieuw.");
        console.log(error);
    }

    async function getAppointments() {
        let query = new URLSearchParams();
        let str = window.location.pathname.split("/");
        if (str[1] === "school") {
            query.append("school__id", str[2]);
        } else if (str[1] === "docent") {
            query.append("docenten__id", str[2]);

        } else {
            query.append("docenten__id", props.data.id);
        }
        let processedquery = {};
        for (let q of query.keys()) processedquery[q] = query.get(q);
        await axios.get(scheduleUrl, { withCredentials: true, params: processedquery })
            .then(function (res) {
                setAppointmentData(res.data);
            })
            .catch(function (error) {
                console.log(error);
                displayToast("Kon geen gegevens ophalen, probeer het later opnieuw.");
            })
    }

    async function addAppointment(changingdata, added, duplicate = -1) {
        if (props.readOnly) return;
        if (duplicate > 0) {
            const changedAppointment = changingdata.data.find(appointment => duplicate === appointment.id);
            added = { ...changedAppointment, ...added };
        }
        if (!added.docenten.filter(x => x).length) {
            displayToast("Een les moet ten minste één docent hebben.")
            return;
        }
        if (props.schoolID) added["school"] = props.schoolID; else added["docenten"] = [props.docentID];
        await axios.post(scheduleUrl, added, { withCredentials: true })
            .then(function (res) {
                changingdata.data = [...changingdata.data, res.data];
            })
            .catch(displayErrs);
    }

    async function changeAppointment(changingdata, changed) {
        if (props.readOnly) return;
        for (let x in changed) {
            if (changed[x].docenten?.filter(x => x)?.length < 1) {
                displayToast("Een les moet ten minste één docent hebben.")
                return;
            }
        }
        const changedAppointment = changingdata.data.find(appointment => changed[appointment.id]);
        await axios.post(scheduleUrl + changedAppointment.id + (deductDelete?"/1":""), changed[changedAppointment.id], { withCredentials: true })
            .then(function (res) {
                changingdata.data = changingdata.data.map(appointment => (
                    changed[appointment.id]
                        ? { ...appointment, ...changed[appointment.id] }
                        : appointment));
            })
            .catch(displayErrs);
    }

    async function deleteAppointment(changingdata, deleted) {
        if (props.readOnly) return;
        await axios.delete(scheduleUrl + deleted + (deductDelete?"/1":""), { withCredentials: true })
            .then(function (res) {
                changingdata.data = changingdata.data.filter(appointment => appointment.id !== deleted);
            })
            .catch(function (error) {
                displayToast("Kon de veranderingen niet opslaan, probeer het later opnieuw.");
            })
    }

    async function commitChanges({ added, changed, deleted }) {
        var changingdata = { data: appointmentData };
        let excludeRecur = false;
        for (let c in changed) {
            if ('exDate' in changed[c]) excludeRecur = true;
        }
        if (deleted !== undefined || excludeRecur) {
            if (!confirmDelete) { // If pop-up hasn't been confirmed yet, show it to decide whether to deduct hours from school or not.
                setPopup(<SweetAlert
                    showCancel
                    confirmBtnText="Ja"
                    cancelBtnText="Nee"
                    title={<span>Optijd geannuleerd?</span>}
                    onConfirm={() => { confirmDelete = true; deductDelete = false; setPopup(null); commitChanges({ undefined, changed, deleted }); return; }} // Don't deduct the hours, school cancelled in time.
                    onCancel={() => { confirmDelete = true; deductDelete = true; setPopup(null); commitChanges({ undefined, changed, deleted }); return; }}  // Deduct the hours, school did not cancelled in time.
                >
                    <span>Heeft de school <span style={{ color: 'red', fontWeight: 'bold' }}>optijd </span>de les geannuleerd?</span>
                    <p className="text-center clickable-text" onClick={() => setPopup(null)} >Annuleer verwijdering</p>
                </SweetAlert>);
                return;
            }
            if (confirmDelete && deleted) await deleteAppointment(changingdata, deleted);
            if (confirmDelete && changed) await changeAppointment(changingdata, changed);
        }
        if (changed && !excludeRecur) {
            if (isShiftPressed) {
                for (let k in changed)
                    await addAppointment(changingdata, changed[k], k); // Send id of 

            } else {
                await changeAppointment(changingdata, changed); // Send id of appointment and changes

            }
        }
        if (added) {
            await addAppointment(changingdata, added);
        }
        getAppointments()
        confirmDelete = false;
    }

    return (<Paper>
        <Scheduler
            data={appointmentData}
            height={660}
            firstDayOfWeek={1} //Monday is first day of Week
        >
            <ViewState
                defaultCurrentViewName="Week"
            />

            <EditingState
                onCommitChanges={commitChanges}

                addedAppointment={addedAppointment}
                onAddedAppointmentChange={setAddedAppointment}

                appointmentChanges={appointmentChanges}
                onAppointmentChangesChange={setAppointmentChanges}

                editingAppointment={editingAppointment}
                onEditingAppointmentChange={setEditingAppointment}
            />
            <EditRecurrenceMenu />

            <DayView
                startDayHour={7}
                endDayHour={19}
            />
            <WeekView
                startDayHour={7}
                endDayHour={19}
            />
            <MonthView />
            <AllDayPanel />
            <Toolbar />
            <DateNavigator />
            <TodayButton />
            <ViewSwitcher />
            <Appointments 
                appointmentComponent={Appointment}
            />
            {props.readOnly ? null : <ConfirmationDialog />}
            <AppointmentTooltip
                showCloseButton
                showOpenButton={!props.readOnly}
                showDeleteButton={!props.readOnly}
                contentComponent={content}
            />
            {props.readOnly ? null : <AppointmentForm readOnly={props.readOnly}
            />}
            <DragDropProvider
                allowDrag={allowDrag}
            />

            <Resources
                data={resources}
                mainResourceName={props.schoolID ? "docenten" : "school"}
            />
        </Scheduler>
        {popup}
    </Paper>
    )
}