import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import displayToast from '../helperFunctions/toasts';
import SchoolBasicInfo from '../form/school/SchoolBasicInfo';
import SchoolAchtergrondInfo from '../form/school/SchoolAchtergrondInfo';
import SchoolPraktischeInfo from '../form/school/SchoolPraktischeInfo';
import SchoolPlanningInfo from '../form/school/SchoolPlanningInfo';
import SchoolContactAdd from '../form/school/SchoolContactAdd';
import SchoolCoordinatorAdd from '../form/school/SchoolCoordinatorAdd';
import { handleInvalidForm } from '../helperFunctions/general';
import FormTabs from '../form/FormTabs';

export default function AddSchool() {
    const history = useHistory();
    let newSchoolID = "";
    const [saveButton, setSaveButton] = useState(false);


    async function add(event) {
        setSaveButton(true);
        event.preventDefault();
        let docentSchoolLink = [];
        let form = new FormData(event.target);
        if (form.get("gekoppelde_contacten[]")) {
            for (let i = 0; i < document.getElementsByName("gekoppelde_contacten[]").length; i++) {
                docentSchoolLink.push({
                    "id": document.getElementsByName("gekoppelde_contacten[]")[i].value
                })
            }
            form.append("gekoppelde_contacten", JSON.stringify(docentSchoolLink));
        }
        await axios.post(`${process.env.REACT_APP_API_URL}/scholen/school/new`, form, { withCredentials: true }).then(function (response) {
            if (response.status === 200) {
                newSchoolID = response.data.id;
                displayToast('School toegevoegd, redirecting naar school!', 'success', history, `/school/${newSchoolID}`);
            }
            else
                displayToast(`Upload afgewezen door server. Statuscode: ${response.status}`)
        }).catch((error, response) => {
            let errors = JSON.stringify(error.response.data);
            displayToast(`${errors}\n Server onbereikbaar. ${error})`);
        }).finally(() => {
            setSaveButton(false);
        })
    }


    return <div className="profile-wrapper">
        <h1 className="text-center">School toevoegen</h1>
        {/* <Container> */}
        <Form onSubmit={add} onInvalid={handleInvalidForm} onKeyDown={(e) => {
            if (e.nativeEvent.key === "Enter" && e.target.type !== "textarea") {
                e.preventDefault();
                const form = e.target.form;
                const index = Array.prototype.indexOf.call(form, e.target);
                form.elements[index + 1].focus();
            }
        }}>
            <div>
                <FormTabs tabTitle={["Algemene informatie", "Contactgegevens", "Praktische informatie", "Achtergrond", "Planning", "Coördinatoren"]}
                    content={[<SchoolBasicInfo editable={true} />
                        , <SchoolContactAdd editable={true} />
                        , <SchoolPraktischeInfo editable={true} />
                        , <SchoolAchtergrondInfo editable={true} />
                        , <SchoolPlanningInfo editable={true} />
                        , <SchoolCoordinatorAdd editable={true} />
                    ]} />

                <Form.Check
                    type="checkbox"
                    id="bevestig"
                    className="mb-2"
                    label="Bevestig toevoeging"
                    required={true}
                />
                <Button type="submit" className="mb-2" disabled={saveButton}>
                    Opslaan
                    </Button>
            </div>
        </Form>
        {/* </Container> */}
    </div >
}