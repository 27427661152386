import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import displayToast from '../helperFunctions/toasts';
import ClipLoader from "react-spinners/ClipLoader";
import { autoSignInURL } from "../helperFunctions/autoSignIn";
import { useDispatch } from "react-redux";
import { updateUserInfo } from "../redux/userSlice";
import {
    useHistory
} from 'react-router-dom';

export default function AutoSignIn(props) {
    const history = useHistory();
    const [failed, setFailed] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        autoSignInURL().then(res => {
            if (res && res.status === 200) {
                dispatch(updateUserInfo(res.data));
                if (res.data.has_usable_password) displayToast('Log-in succesvol, redirecting naar /me', 'success', history, `/me`, 1000);
                else displayToast('Log-in succesvol, redirecting...', 'success', history, `/change-password`, 1000);

            } else {
                displayToast(`Verkeerde auto-signin link.`);
                setFailed(true);
            }
        })
    }, [])
    return <div>
        {!failed ? <div className="profile-loader"><ClipLoader size={150} color={"#e02f2f"} loading={true} />
            <h2>Automatisch inloggen... Even wachten aub...</h2> </div> : <div className="profile-loader"> <h2 className="text-center"> Automatisch inloggen mislukt. Gebruik je inloggegevens of stuur ons een e-mail op: <a href="mailto:contact@debijlesmeester.nl">contact@debijlesmeester.nl</a> </h2></div>}
    </div>
}