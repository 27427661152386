import React, { useState } from 'react';
import "./ScrollToTop.scss";

function ScrollToTop() {
    const [showScroll, setShowScroll] = useState(false)
    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false)
        }
    };
    window.addEventListener('scroll', checkScrollTop)

    const scrollTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
     };
    return (
        <div className="scroll-top-wrapper">
            <span className={"scrollTop"} onClick={scrollTop} style={{display: showScroll ? 'flex' : 'none'}}>&#8250;</span>
        </div>
    )
}

export default ScrollToTop;