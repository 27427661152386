import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "./notesForm.scss";

export default function Notities(prop) {
	
	return <Form onSubmit={prop.updateNotes}>
		<div className="notes-wrapper">
			<Form.Group>
				<Form.Label>Notities</Form.Label>
				<Form.Control as="textarea" rows={5} defaultValue={prop.notesForOverview} name='notes' id="allnotes" readOnly={true} />
				<br />
				<Form.Control type="text" name='note' placeholder="Vul hier een notitie in..." id="notefield" value={prop.input} onChange={(event) => prop.setInput(event.target.value)} />
				<br />
				<Button type="submit" className="mb-2">
					Voeg notitie toe
				</Button>
			</Form.Group>
		</div>
	</Form>
}