import displayToast from './toasts';

function formatDateTime(date, format) {
    let twoDigitStr = (x) => (x < 10) ? "0" + x : "" + x;
    return format.replaceAll("Y", date.getFullYear())
        .replaceAll("M", twoDigitStr(date.getMonth() + 1))
        .replaceAll("D", twoDigitStr(date.getDate()))
        .replaceAll("h", twoDigitStr(date.getHours()))
        .replaceAll("m", twoDigitStr(date.getMinutes()))
}


function handleInvalidForm(e) {
    e.preventDefault();
    e.stopPropagation();
    // prevent default
    let x = e.target;
    while (x && x.classList && !x.classList.toString().includes("react-tabs__tab-panel")) x = x.parentNode;
    if (x && x.id) {
        let y = x.id.split("-");
        y.push(y.pop() - 1);
        document.getElementById(y.join("-")).click();
        // switch to right tab and focus on right element
    }

    let name = e.target.name;
    if (e.target.labels[0]) name = e.target.labels[0].innerText;
    else if (e.target.dataset.displayname) name = e.target.dataset.displayname;
    displayToast(`Vul het volgende veld a.u.b. in: ${name}`);
    e.target.focus();
}

export {
    formatDateTime,
    handleInvalidForm
};