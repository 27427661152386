import axios from 'axios';
import displayToast from './toasts';

async function autoSignInURL() {
    const queryString = window.location.search;
    let token = new URLSearchParams(queryString).get('token');
    let id = new URLSearchParams(queryString).get('id');
    return axios.get(`${process.env.REACT_APP_API_URL}/scholen/autosignin/${token}/${id}`, {
            withCredentials: true
        })
        .catch(function (error) {
            displayToast(`Server onbereikbaar. ${error})`);
        });
}
async function getUserInfo() {
    return axios.get(`${process.env.REACT_APP_API_URL}/scholen/me`, {
            withCredentials: true
        })
        .catch(function (error) {
            console.log("Inloggegevens timed out, log opnieuw in.")
        });
}
export {
    autoSignInURL,
    getUserInfo
  };