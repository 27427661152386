import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { calculateTotalOf } from '../helperFunctions/csvHelpers';

export default function FinancialOverview() {
    const [totalHours, setTotalHours] = useState(0)
    const [year, setYear] = useState(new Date().getFullYear());
    const [yearList, setYearList] = useState([]);
    const [month, setMonth] = useState(null);
    const [downloadSheet, setDownloadSheet] = useState();
    const [teacherView, setTeacherView] = useState(null);
    let urlScheduleTotal;

    useEffect(() => {
        urlScheduleTotal = '/scholen/schedule/events/list/past?';
        addDateFilter();
        addFieldsToUrl();
        calculateTotalOf(`${process.env.REACT_APP_API_URL}${urlScheduleTotal}`, teacherView).then(res => {
            setTotalHours(res.totalHours);
            setYearList(res.years);
            setDownloadSheet(res.uri);
        })
    }, [year, teacherView, month]); // repopulate URI and info whenever years or views change.

    function addFieldsToUrl() {
        let fields = ['eventDuration', 'docenten__user__first_name', 'docenten__user__last_name', 'docenten__iban', 'school__id', 'school__name'];
        fields.forEach(item => urlScheduleTotal += "&fields[]=" + item);
    }

    function addDateFilter() {
        urlScheduleTotal += "startDate=" + (year ? year : "") + (month ? `-${month}-` : "");
    }

    return <div className="pl-4">
        <div className="d-flex justify-content-start">
            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {teacherView === null ? "Kies een view" : teacherView ? "Docenten" : "Scholen"}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a className="dropdown-item" onClick={() => setTeacherView(true)}>Docenten</a>
                <a className="dropdown-item" onClick={() => setTeacherView(false)}>Scholen</a>
            </div>
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {year ? year : "Kies een jaar"}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {yearList.map(item => {
                        console.log(item)
                        return (
                            <a className="dropdown-item" onClick={() => setYear(item)}>{item}</a>
                        )
                    })}
                </div>

            </div>{
                year ?
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {month ? month : "Kies een maand"}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a className="dropdown-item" onClick={() => setMonth("01")}>januari</a>
                            <a className="dropdown-item" onClick={() => setMonth("02")}>februari</a>
                            <a className="dropdown-item" onClick={() => setMonth("03")}>maart </a>
                            <a className="dropdown-item" onClick={() => setMonth("04")}>april </a>
                            <a className="dropdown-item" onClick={() => setMonth("05")}>mei </a>
                            <a className="dropdown-item" onClick={() => setMonth("06")}>juni </a>
                            <a className="dropdown-item" onClick={() => setMonth("07")}>juli  </a>
                            <a className="dropdown-item" onClick={() => setMonth("08")}>augustus  </a>
                            <a className="dropdown-item" onClick={() => setMonth("09")}>september  </a>
                            <a className="dropdown-item" onClick={() => setMonth("10")}>oktober   </a>
                            <a className="dropdown-item" onClick={() => setMonth("11")}>november   </a>
                            <a className="dropdown-item" onClick={() => setMonth("12")}>december    </a>
                        </div>
                    </div> : null}
        </div>
        <div className="mt-5">
            <p>Total hours: {totalHours}</p>
            {/* We are using the csv as a string here to simplify the download. We store the data as a string and pass it as the href. By clicking on the link
        the user will start downloading the excelsheet. The name is decided by the download attribute. */}
            <a href={downloadSheet} download={(() => { let currDate = new Date(); currDate = currDate.getFullYear() + "-" + (currDate.getMonth() + 1) + "-" + currDate.getDate() + ".csv"; return "financials/" + (month ? month + "-" : "") + (year ? year : "") + "/" + currDate })()}>Download excel sheet</a></div>
        {/* Download format is: subject_range_dateOfDownload.  */}
    </div >
}