import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import displayToast from '../helperFunctions/toasts';
import niceConfirm from '../helperFunctions/confirmPopup';
import { getAllScholen } from '../helperFunctions/scholen';
import DocentBasicInfo from "../form/docent/DocentBasicInfo";
import DocentScreeningInfo from "../form/docent/DocentScreeningInfo";
import DocentScholenAdd from '../form/docent/DocentScholenAdd';
import { useForm } from 'react-hook-form';
import { handleInvalidForm } from '../helperFunctions/general';
import FormTabs from '../form/FormTabs';

export default function Add() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [schools, setSchools] = useState();
    const [saveButton, setSaveButton] = useState(false);
    let [changeSchoolData, setChangeSchoolData] = useState(false);
    let confirmAdd = false;
    let confirmVog = false;
    let confirmEmail = false;
    let [popup, setPopup] = useState(null);
    const { register, handleSubmit } = useForm();
    let newUserID = "";

    useEffect(() => {
        getAllScholen().then(res => {
            setSchools(res.data);
            setLoading(true);
            setChangeSchoolData(true);
        })
    }, [])


    async function add(data) {
        setSaveButton(true);
        if ((data["contract"] || data["defaultContract"]) && !confirmAdd) {
            setPopup(niceConfirm('Deze docent heeft nog geen contract ontvangen. Het aanleveren van een contract zal een e-mail naar deze docent sturen waarbij zij hun contract kunnen ondertekenen. Weet je zeker dat je dit wilt?', () => setPopup(null), () => { confirmAdd = true; add(data) }, () => { setPopup(null) }));
            setSaveButton(false);
            return;
        }
        if (data['vog_verstuurd'] && !confirmVog) {
            setPopup(niceConfirm('Je probeert aan te geven dat deze docent zijn/haar VOG aan heeft gevraagd. Dit betekent dat als deze na 28 dagen nog niet is geüpload er een herinneringsmail wordt verstuurd. Weet je zeker dat je dit wilt?', () => setPopup(null), () => { confirmVog = true; add(data) }, () => { setPopup(null) }));
            setSaveButton(false);
            return;
        }
        let gekoppelde_scholen = [];
        if (data.gekoppelde_scholen) {
            data.gekoppelde_scholen.map((id, i) =>
                gekoppelde_scholen.push({
                    "school": id,
                    "role": data.role[i]
                }))
        }
        ["gekoppelde_scholen", "role", "id"].forEach(e => delete data[e]);
        data["gekoppelde_scholen"] = gekoppelde_scholen;

        if (data["contract"] && data["contract"][0]) data["contract"] = data["contract"][0]; else if (data["contract"]) { delete data.contract }
        if (data["vog"] && data["vog"][0]) data["vog"] = data["vog"][0]; else if (data["vog"]) { delete data.vog }
        if (data["vog_bewijs"] && data["vog_bewijs"][0]) data["vog_bewijs"] = data["vog_bewijs"][0]; else if (data["vog_bewijs"]) { delete data.vog_bewijs }

        let formData = new FormData();
        for (var key in data) {
            if (key === "gekoppelde_scholen") formData.append(key, JSON.stringify(data[key]));
            else if (!data[key]) { }
            else if (data[key].name) {
                formData.append(key, data[key], data[key].name);
            }
            else formData.append(key, data[key]);
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/scholen/docent/new`, formData, { withCredentials: true }).then(function (response) {
            if (response.status === 200) {
                newUserID = response.data.id;
                displayToast('Docent toegevoegd, redirecting naar docent!', 'success', history, `/docent/${newUserID}`);
            }
            else
                displayToast(`Upload afgewezen door server. Statuscode: ${response.status}`)
        }).catch((error, response) => {
            let errors = error?.response?.data;
            if (errors?.non_field_errors) {
                for (let err of errors.non_field_errors) displayToast(err);
                return;
            }
            displayToast(`${JSON.stringify(errors)}\n Server onbereikbaar. ${error})`);
        }).finally(() => {
            setSaveButton(false);
        })
    }

    async function checkEmail(event) {
        if (event.target.value)
            await axios.post(`${process.env.REACT_APP_API_URL}/scholen/email`, { email: event.target.value }, { withCredentials: true }).then(function (response) {
                if (response.status === 200) {
                    newUserID = response.data.id;
                    if (!confirmEmail) {
                        if (response.data.bms) {
                            setPopup(niceConfirm('Docent bestaat al binnen BMS, wil je de docent openen?', () => setPopup(null), () => {
                                confirmEmail = true; displayToast('Docent bestaat al, redirecting naar docent!', 'success', history, `/docent/${newUserID}`);
                            }, () => { setPopup(null) }));
                            return;
                        } else {
                            let formData = new FormData();
                            let data = response.data;
                            // for (var key in data) {
                            //     if(key === "")
                            //     formData.append(key, JJSON.stringify(data[key]));
                            // }
                            for (var key in data) {
                                if (key === "gekoppelde_scholen") formData.append(key, JSON.stringify(data[key]));
                                else if (!data[key] || key === "id_verwerkt" || key === "id_verwerkt_bms") { }
                                else if (data[key].name) {
                                    formData.append(key, data[key], data[key].name);
                                }
                                else if (key !== "geolocatie" && key !== "geolocatie2") formData.append(key, data[key]);
                            }
                            formData["bms"] = true;
                            setPopup(niceConfirm('Docent bestaat al in BMO, maar nog niet in BMS. Wil je de docent toevoegen?', () => setPopup(null), () => {
                                confirmEmail = true; axios.post(`${process.env.REACT_APP_API_URL}/scholen/docent/${response.data.id}`, formData, {
                                    withCredentials: true
                                }).then(function (response) {
                                    displayToast('Docent bestaat al, redirecting naar docent!', 'success', history, `/docent/${newUserID}`);
                                })
                            }, () => { setPopup(null) }));
                            return;

                        }
                    }
                } else if (response.status === 204)
                    return
                else
                    displayToast(`Upload afgewezen door server. Statuscode: ${response.status}`)
            }).catch((error, response) => {
                if (error.response.status === 306) {
                    displayToast('E-mail heeft al een admin- of ouderaccount, en kan dus niet als docent in BMS gezet worden. Kies een andere e-mail.');
                } else {
                    let errors = JSON.stringify(error.response.data);
                    displayToast(`${errors}\n Server onbereikbaar. ${error})`);
                }
            })
    }
    return <div className="profile-wrapper">
        <h1 className="text-center">Docent toevoegen</h1>
        <Container>
            <Form onSubmit={handleSubmit(add)} onInvalid={handleInvalidForm} onKeyDown={(e) => {
                if (e.nativeEvent.key === "Enter" && e.target.type !== "textarea") {
                    e.preventDefault();
                    const form = e.target.form;
                    const index = Array.prototype.indexOf.call(form, e.target);
                    form.elements[index + 1].focus();
                }
            }}>
                <FormTabs tabTitle={["Basisinformatie", "Contractinformatie", "Scholen"]}
                    content={[
                        <DocentBasicInfo register={register} checkEmail={checkEmail} />
                        ,
                        <DocentScreeningInfo register={register} />
                        ,
                        <DocentScholenAdd schools={schools} loading={loading} changeSchoolData={changeSchoolData} register={register} />
                    ]} />

                <Form.Check
                    type="checkbox"
                    id="bevestig"
                    className="mb-2"
                    label="Bevestig toevoeging"
                    required={true}
                    register={register} />
                <Button type="submit" className="mb-2" disabled={saveButton}>
                    Voeg docent toe
                    </Button>
            </Form>
        </Container>
        {popup}
    </div>
}