import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import Button from 'react-bootstrap/Button';
import displayToast from '../helperFunctions/toasts';
import niceConfirm from '../helperFunctions/confirmPopup';
import { formatDateTime } from '../helperFunctions/general';
import { useHistory } from 'react-router-dom';
import { getAllScholen } from '../helperFunctions/scholen';
import DocentBasicInfo from "../form/docent/DocentBasicInfo";
import DocentScreeningInfo from "../form/docent/DocentScreeningInfo";
import DocentScholenAdd from '../form/docent/DocentScholenAdd';
import Notities from '../profiles/notesForm';
import { useForm } from 'react-hook-form';
import FormTabs from "../form/FormTabs";
import EditButton from '../form/EditButton';
import "./Profile.scss"
import { handleInvalidForm } from '../helperFunctions/general';
import Schedule from '../scheduler/Schedule';
import {useSelector} from "react-redux";

export default function Profile(props) {
    let user = useSelector((state) => state.user.user)
    const history = useHistory();
    let preventDefaultEdit = Boolean(window.location.pathname.match(/docent\/\d/));
    const [isReadOnly, setReadOnly] = useState(preventDefaultEdit);
    var parts = window.location.pathname.split('/');
    var docentID = parts[parts.length - 1];
    const [loading, setLoading] = useState(false);
    const [saveButton, setSaveButton] = useState(false);
    const [schools, setSchools] = useState();
    let [prop, setProps] = useState();
    let [notesForOverview, setNotes] = useState();
    let [vog, setVog] = useState();
    let [vogBewijs, setVogBewijs] = useState();
    let [contract, setContract] = useState();
    let [saveHandtekening, setSaveHandtekening] = useState();
    let [mailName, setMailName] = useState();
    let [input, setInput] = useState("");
    const { register, handleSubmit, reset } = useForm({});
    let url = {
        'D': `${process.env.REACT_APP_API_URL}/scholen/self/docent`,
        'A': `${process.env.REACT_APP_API_URL}/scholen/docent/${docentID}`
    }[user.user_type];
    let isAdmin = user.user_type === 'A';
    let changeSchoolData = isAdmin;
    let triggers = ["contract", "email", "first_name", "last_name"];
    let confirmAdd = false;
    let confirmVog = false;
    let docentconfirm = false;
    let [popup, setPopup] = useState(null);
    let tabTitles = null;
    let tabContents = null;

    if (docentID == 'me' ^ user.user_type == 'D') {
        history.push({'D':'/me', 'A':'/docenten-bms'}[user.user_type]);
    }

    useEffect(() => {
        if (changeSchoolData)
            getAllScholen().then(res => {
                setSchools(res.data);
                setLoading(true);
            })
    }, [changeSchoolData])

    useEffect(() => { getDocent() }, []);

    useEffect(() => { getMail() }, []);

    async function getMail() {
        if (user.user_type !== 'D') {
            await axios.get(process.env.REACT_APP_API_URL + "/scholen/mail/99901", { withCredentials: true })
                .then(function (response) {
                    setMailName(response.data['title'])
                })
                .catch(function (error) {
                })
        }
    }

    async function getDocent() {
        await axios.get(url, { withCredentials: true })
            .then(function (response) {

                // Set loading true so that we can force rerenders after everything is set-up
                setLoading(true);
                setProps(response.data);

                response.data.vog ? setVog(process.env.REACT_APP_API_URL + response.data.vog) : setVog(null);
                response.data.contract ? setContract(process.env.REACT_APP_API_URL + response.data.contract) : setContract(null);
                response.data.vog_bewijs ? setVogBewijs(process.env.REACT_APP_API_URL + response.data.vog_bewijs) : setVogBewijs(null);

                // Prepare the reset function to set all controlled defaultValues
                delete response.data.contract;
                delete response.data.vog;
                let gekoppelde_scholen = response.data.gekoppelde_scholen;
                delete response.data.gekoppelde_scholen;

                reset(response.data);
                response.data.gekoppelde_scholen = gekoppelde_scholen;
                setLoading(false);

                if (user.user_type !== 'D') getNotes();
                return response.data;
            }).then()
            .catch(function (error) {
            });
    }

    async function editProfile(data) {
        setSaveButton(true);
        if (user.user_type !== 'D') {
            if (!confirmAdd && prop) {
                let numAttrs = 0;
                let newAttrs = false;
                for (let t of triggers) {
                    if (prop[t]) numAttrs++;
                    else if (data[t] || (t === "contract" && data["defaultContract"] && data["defaultContract"] !== -1)) { numAttrs++; newAttrs = true; }
                }
                confirmAdd = (newAttrs && numAttrs >= triggers.length) ? confirmAdd : true;
            }

            if (!confirmAdd) {
                setPopup(niceConfirm(`Deze docent heeft nog geen welkomstmail ontvangen. Omdat na opslaan de gegevens (voornaam, achternaam, e-mailadres en contract) compleet zijn zal dit de mail "${mailName}" versturen. Weet je zeker dat je dit wilt?`, () => setPopup(null), () => { confirmAdd = true; editProfile(data) }, () => { setPopup(null) }));
                setSaveButton(false);
                return;
            }
            if (data['vog_verstuurd'] && data.vog_verstuurd && !confirmVog) {
                setPopup(niceConfirm(
                    'Je probeert aan te geven dat deze docent zijn/haar VOG aan heeft gevraagd. ' +
                    'Dit betekent dat er een mail wordt verstuurd naar de docent met instructies om de VOG-aanvraag af te ronden. ' +
                    'Verder wordt er na 2 dagen een herinneringsmail gestuurd als er nog geen betalingsbewijs is geüpload. ' +
                    'Als er na 4 weken nog geen VOG is geüpload wordt er nog een herinneringsmail verstuurd. ' +
                    'Weet je zeker dat je deze drie mails wilt laten versturen?'
                    , () => setPopup(null), () => { confirmVog = true; editProfile(data) }, () => { setPopup(null) }));
                setSaveButton(false);
                return;
            }
        } else if (!docentconfirm && document.getElementById("bevestig-contract") && document.getElementById("bevestig-contract").value === 'on') {
            setPopup(niceConfirm('Na het opslaan kun je je ID, handtekening en IBAN niet meer aanpassen. Weet je zeker dat deze kloppen?', () => setPopup(null), () => { docentconfirm = true; editProfile(data) }, () => { setPopup(null) }));
            setSaveButton(false);
            return;
        }

        // Set files properly to be sent if present
        if (data["contract"] && data["contract"][0]) data["contract"] = data["contract"][0]; else if (data["contract"]) { delete data.contract }
        if (data["vog"] && data["vog"][0]) data["vog"] = data["vog"][0]; else if (data["vog"]) { delete data.vog }
        if (data["vog_bewijs"] && data["vog_bewijs"][0]) data["vog_bewijs"] = data["vog_bewijs"][0]; else if (data["vog_bewijs"]) { delete data.vog_bewijs }
        if (data["id_front"] && data["id_front"][0]) data["id_front"] = data["id_front"][0];
        if (data["id_back"] && data["id_back"][0]) data["id_back"] = data["id_back"][0];
        if (saveHandtekening) data["signature"] = document.querySelectorAll('canvas')[1].toDataURL("image/png");

        // Format all gekoppelde scholen properly
        let gekoppelde_scholen = [];
        if (data.gekoppelde_scholen) {
            data.gekoppelde_scholen.filter(x => x).map((id, i) =>
                gekoppelde_scholen.push({
                    school: id,
                    docent: docentID,
                    role: data.role[i],
                    id: data.id && data.id[i] ? data.id[i] : null
                }))
        }

        ["gekoppelde_scholen", "role", "id"].forEach(e => delete data[e]);

        data["gekoppelde_scholen"] = gekoppelde_scholen;

        let formData = new FormData();
        for (var key in data) {
            if (key === "gekoppelde_scholen") formData.append(key, JSON.stringify(data[key]));
            else if (!data[key]) { }
            else if (data[key].name) {
                formData.append(key, data[key], data[key].name);
            }
            else formData.append(key, data[key]);
        }

        await axios.post(url, formData, {
            withCredentials: true
        }).then(function (response) {
            if (response.status === 200)
                displayToast('Profiel gewijzigd, reloading page!', 'success', history);
            else
                displayToast(`Upload afgewezen door server. Statuscode: ${response.status}`)
        }).catch((error, response) => {
            let errors = error?.response?.data;
            if (errors?.non_field_errors) {
                for (let err of errors.non_field_errors) displayToast(err);
                setSaveButton(false);
                return;
            }
            displayToast(`${JSON.stringify(errors)}\n Server onbereikbaar. ${error})`);
        }).finally(() => {
            setSaveButton(false);
        })
        setSaveButton(false);
    }

    async function getNotes() {
        await axios.get(`${process.env.REACT_APP_API_URL}/scholen/docent/${docentID}/notes`, { withCredentials: true })
            .then(function (response) {
                setNotes(response.data.map((x) => { return `[${formatDateTime(new Date(x.time), "Y-M-D h:m")} - ${x.creator_first_name} ${x.creator_last_name}] ${x.note}` }).join("\n"));
                let ta = document.getElementById("allnotes");
                ta.scrollTop = ta.scrollHeight;
            }).catch((error, response) => {
                let errors = JSON.stringify(error && error.response ? error.response.data : null);
                displayToast(`${errors}\n Server onbereikbaar. ${error})`);
            });
    }


    async function updateNotes(event) {
        event.preventDefault();
        let form = new FormData(event.target);
        await axios.post(`${process.env.REACT_APP_API_URL}/scholen/docent/${docentID}/notes`, form, { withCredentials: true }).then(function (response) {
            if (response.status === 200) {
                setInput("");
                getNotes();
            } else
                displayToast(`Upload afgewezen door server. Statuscode: ${response.status}`)
        }).catch((error, response) => {
            let errors = JSON.stringify(error.response.data);
            displayToast(`${errors}\n Server onbereikbaar. ${error})`);
        });
    }


    if (prop) {
        tabTitles = ["Basisinformatie"];
        tabContents = [<DocentBasicInfo isReadOnly={isReadOnly} register={register} first_name={prop.first_name} last_name={prop.last_name} email={prop.email} telefoonnummer={prop.telefoonnummer} iban={prop.iban} geslacht={prop.geslacht} id_verwerkt={prop.id_verwerkt_bms} userType={user.user_type} straat={prop.straat} huisnummer={prop.huisnummer} postcode={prop.postcode} woonplaats={prop.woonplaats} />]
        if (!(props.coordinator)) {
            tabTitles.push(isAdmin ? "Screening info" : "Contract info");
            tabContents.push(<DocentScreeningInfo isReadOnly={isReadOnly} register={register} contract={contract} userType={user.user_type} setSaveHandtekening={setSaveHandtekening} vog={vog} contract_signed={prop.contract_signed_bms} id_verwerkt={prop.id_verwerkt_bms} vog_verstuurd={prop.vog_verstuurd} vog_bewijs={vogBewijs} />);
        }

        if (isAdmin || prop.contract_signed_bms) {
            tabTitles.push("Scholen");
            tabContents.push(<DocentScholenAdd isReadOnly={isReadOnly} register={register} gekoppelde_scholen={prop.gekoppelde_scholen} changeSchoolData={changeSchoolData} loading={loading} schools={schools} userType={user.user_type} />);
        }
        tabTitles.push("Rooster");
        tabContents.push(<Schedule readOnly={true} getScholen={props.getScholen} data={prop} userType={user.user_type} />);
    }

    return <div className="profile-wrapper">
        {!prop ? <div className="profile-loader"><ClipLoader size={150} color={"#e02f2f"} loading={true} /> </div> :
            <><Container>
                {preventDefaultEdit ? <EditButton isReadOnly={isReadOnly} setReadOnly={setReadOnly} /> : null}
                {user.user_type === 'D' ? <h2>Mijn gegevens</h2> : <><h2>Profiel</h2><Notities updateNotes={updateNotes} notesForOverview={notesForOverview} input={input} setInput={setInput} /></>}
                <Form onSubmit={handleSubmit(editProfile)} className="profile-docent-form" onInvalid={handleInvalidForm} onKeyDown={(e) => {
                    if (e.nativeEvent.key === "Enter" && e.target.type !== "textarea") {
                        e.preventDefault();
                        const form = e.target.form;
                        const index = Array.prototype.indexOf.call(form, e.target);
                        form.elements[index + 1].focus();
                    }
                }}>
                    <div className="profile-docent-form-wrapper">
                        <FormTabs tabTitle={tabTitles} content={tabContents} />

                        {true ? null : user.user_type === 'D' ? null : <Form.Check
                            type="checkbox"
                            id="bevestig"
                            className="mb-2"
                            label="Bevestig profiel wijzigingen"
                            required={true}
                            ref={register}
                        />}
                        {!isReadOnly ? <>
                            <Button type="submit" className="mb-2" disabled={saveButton}>
                                Opslaan
                            </Button>
                            <Button type="reset" className="mb-2" variant="secondary" onClick={() => setReadOnly(true)} disabled={saveButton}>
                                Annuleren
                            </Button> </> : null}
                    </div>
                </Form>
            </Container>
                {popup}</>
        }
    </div >
}