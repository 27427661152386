import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import displayToast from '../../helperFunctions/toasts';
import AddContact from '../../docenten/AddContact';
import { Col, Row } from 'react-bootstrap';

let renderedContacts = 0;

export default function SchoolContactAdd(prop) {
    let [contactPersons, setContactPersons] = useState();
    let [contactPersonsDivs, setContactPersonsDivs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [toDelete, setToDelete] = useState(null);

    useEffect(() => {
        if (prop.editable)
            getAllContactperson().then(res => {
                setContactPersons(res.data);
                setLoading(true);
            })
    }, [prop.contactChanged, prop.editable])

    useEffect(() => {
        if (toDelete !== null) {
            removeContact(toDelete);
            setToDelete(null);
        }
    }, [toDelete]);

    useEffect(() => {
        let rows = prop.gekoppelde_contacten?.map((gekoppelde_contacten, i) => {
            renderedContacts++;
            let tmp = renderedContacts;
            return <div id={`gekoppelde_contacten[${renderedContacts}]`} key={`contacten-${i}`}>
                <h3>Contactpersoon #{renderedContacts}</h3>
                <Form.Group controlId="beschikbaarheid">
                    <AddContact person={gekoppelde_contacten.person} deleteBtn={
                        <Button className="btn-danger" onClick={() => setToDelete(tmp)}>Verwijderen</Button>
                        } number={renderedContacts} />

                    </Form.Group>
                </div>;
            });
        setContactPersonsDivs(rows);
    }, [prop.gekoppelde_contacten])

    function removeContact(idx) {
        setContactPersonsDivs([...contactPersonsDivs].filter((div) => div.props.id !== `gekoppelde_contacten[${idx}]`));
    }
    async function getAllContactperson() {
        return axios.get(`${process.env.REACT_APP_API_URL}/scholen/contact/all`, { withCredentials: true })
            .catch((error, response) => {
                let errors = JSON.stringify(error.response.data);
                displayToast(`${errors}\n Server onbereikbaar. ${error})`);
            })
    }

    function renderContactLink(extra) {
        let rows = [...contactPersonsDivs];
        for (var i = 0; i < extra; i++) {
            // note: we are adding a key prop here to allow react to uniquely identify each
            // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
            renderedContacts++;
            let tmp = renderedContacts;
            rows.push(<div id={`gekoppelde_contacten[${renderedContacts}]`} key={`link-${renderedContacts}`}>
                <h3>Koppel contactpersoon</h3>
                <Row>
                    <Col>
                        <Form.Group controlId="beschikbaarheid">
                            <Form.Control as="select" defaultValue="" name="gekoppelde_contacten[]">
                                <option value="">Kies...</option>
                                {loading ? contactPersons?.map(contactPerson => { return <option value={contactPerson.id} key={contactPerson.id}>{contactPerson.first_name} {contactPerson.last_name}</option>; }) : null}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Button className="btn-danger" onClick={() => setToDelete(tmp)}>Verwijderen</Button>
                    </Col>
                </Row>
            </div>);
        }
        setContactPersonsDivs(rows);
    }

    function renderContactAdd(extra) {
        let rows = [...contactPersonsDivs];
        for (var i = 0; i < extra; i++) {
            // note: we are adding a key prop here to allow react to uniquely identify each
            // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
            renderedContacts++;
            let tmp = renderedContacts;
            rows.push(<div style={{ borderRadius: "23px", padding: "0.25rem", marginTop: "1rem" }} id={`gekoppelde_contacten[${renderedContacts}]`} key={`add-${renderedContacts}`}>
                <h3 className="mb-4">Voeg contactpersoon toe</h3>
                <Form.Group controlId="beschikbaarheid">
                    <AddContact deleteBtn={
                        <Button className="btn-danger" onClick={() => setToDelete(tmp)}>Verwijderen</Button>
                    } number={renderedContacts} />
                </Form.Group>
            </div>);
        }
        setContactPersonsDivs(rows);
    }

    return <div className="form-background">
        <fieldset disabled={prop.isReadOnly}>
            {contactPersonsDivs ? contactPersonsDivs : null}
            {prop.editable ? <Button className="btn-contact" onClick={() => renderContactLink(1)}>Contactpersoon koppelen</Button> : null}
            {prop.editable ? <Button className="btn-contact" onClick={() => renderContactAdd(1)}>Contactpersoon toevoegen</Button> : null}
        </fieldset>
    </div>
}