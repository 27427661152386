import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

export default function SchoolAdresInfo(prop) {
    return <div>
        <fieldset disabled={!prop.editable || prop.isReadOnly}>
            <Form.Row>
                <Form.Group as={Col} controlId="formGridAddress1">
                    <Form.Label>Straatnaam</Form.Label>
                    <Form.Control placeholder="Prinsengracht" defaultValue={prop ? prop.straat : null} name='straat' />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridStraatNum">
                    <Form.Label>Huisnummer</Form.Label>
                    <Form.Control placeholder="701" defaultValue={prop ? prop.huisnummer : null} name='huisnummer' />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridStraatNum">
                    <Form.Label>Toevoeging</Form.Label>
                    <Form.Control placeholder="H" name='toevoegingen' defaultValue={prop ? prop.toevoegingen : null} />
                </Form.Group>

                <Form.Group controlId="formGridZip">
                    <Form.Label>Postcode</Form.Label>
                    <Form.Control defaultValue={prop ? prop.postcode : null} placeholder="1017JV" name='postcode' />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridCity">
                    <Form.Label>Plaats</Form.Label>
                    <Form.Control placeholder="Amsterdam" defaultValue={prop ? prop.woonplaats : null} name='woonplaats' />
                </Form.Group>
            </Form.Row>
        </fieldset>
    </div >
}