import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import AutoComplete from '../AutoComplete.jsx';


let renderedScholen = 0;

export default function DocentScholenAdd({ register, ...prop }) {
    let [schoolDivs, setSchoolDivs] = useState([]);
    const [toDelete, setToDelete] = useState(null);
    let [schoolOpts, setSchoolOpts] = useState([]);

    useEffect(() => {
        if (prop.schools)
            setSchoolOpts(prop.schools.map(school => { return {"value": school.id, "label": school.name}}));
    }, [prop.schools])

    useEffect(() => {
        if (!prop.gekoppelde_scholen) return;
        let rows = prop.gekoppelde_scholen.map((gekoppelde_school, i) => {
            renderedScholen++;
            let tmp = renderedScholen;
            return (<div id={`gekoppelde_scholen[${renderedScholen}]`}><h3>{gekoppelde_school.school_name}</h3> 
                <Link to={`/school/${gekoppelde_school.school}`}>Bekijken</Link><br  />

                {prop.changeSchoolData ?
                    <Button onClick={() => setToDelete(tmp)}>Verwijderen</Button>
                    : null
                }
                <Form.Group controlId="gekoppelde_scholen">
                    <Form.Label>Koppel aan school</Form.Label>
                    {/* TODO: figure out why autocomplete has no default value */}
                    {schoolOpts?<AutoComplete register={register} name={`gekoppelde_scholen[${i}]`} options={schoolOpts} disabled={!prop.changeSchoolData} defaultValue={schoolOpts.find(option => option.value === gekoppelde_school.school)} />:null}
                </Form.Group>
                    <Form.Group controlId="Rol">
                        <Form.Label>Rol</Form.Label>
                        <Form.Control ref={register} as="select" defaultValue={gekoppelde_school.role} name={`role[${i}]`} disabled={!prop.changeSchoolData}>
                            <option value="">Kies...</option>
                            <option value="HD">Hoofddocent</option>
                            <option value="D">Docent</option>
                            <option value="OD">Ondersteunend docent</option>
                        </Form.Control>
                        <input ref={register} type="hidden" id="schoolID" name={`id[${i}]`} value={gekoppelde_school.id}></input>
                    </Form.Group>
                    </div>)
        })
        setSchoolDivs(rows);
    }, [prop.changeSchoolData, schoolOpts, prop.gekoppelde_scholen, register])

    useEffect(() => {
        if (toDelete !== null) {
            removeSchool(toDelete);
            setToDelete(null);
        }
    }, [toDelete]);

    function removeSchool(idx) {
        setSchoolDivs([...schoolDivs].filter((div) => div.props.id !== `gekoppelde_scholen[${idx}]`));
    }

    function renderSchoolAdd(extra) {
        let rows = [...schoolDivs];
        for (var i = 0; i < extra; i++) {
            // note: we are adding a key prop here to allow react to uniquely identify each
            // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
            renderedScholen++;
            let tmp = renderedScholen;
            rows.push(<div id={`gekoppelde_scholen[${renderedScholen}]`} key={`school-add-${renderedScholen}`}> <h3>Nieuwe koppeling</h3>
                {prop.changeSchoolData ?
                    <Button id={i} onClick={() => setToDelete(tmp)}>Verwijderen</Button>
                    : null
                }
                <Form.Group controlId="beschikbaarheid">
                <Form.Label>Koppel aan school</Form.Label>
                {schoolOpts?<AutoComplete register={register} name={`gekoppelde_scholen[${renderedScholen}]`} options={schoolOpts} disabled={!prop.changeSchoolData} />:null}
            </Form.Group>
                <Form.Group controlId="Rol">
                    <Form.Label>Rol</Form.Label>
                    <Form.Control ref={register} as="select" name={`role[${renderedScholen}]`}>
                        <option value="">Kies...</option>
                        <option value="HD">Hoofddocent</option>
                        <option value="D">Docent</option>
                        <option value="OD">Ondersteunend docent</option>
                    </Form.Control>
                </Form.Group> 
                </div>);
        }
        setSchoolDivs(rows);
    }

    return <div>
        <fieldset disabled={prop.isReadOnly}>
        {schoolDivs}
        {prop.changeSchoolData ?
            <><Button onClick={() => renderSchoolAdd(1)}>Toevoegen</Button></>
            : null
        }
        </fieldset>
    </div>
}