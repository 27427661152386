import SweetAlert from 'react-bootstrap-sweetalert';

export default function niceConfirm(msg, closefunc=() => {}, confirmfunc=() => {}, cancelfunc=() => {}) {
	return <SweetAlert
  			warning
  			showCancel
  			confirmBtnBsStyle="danger"
  			title="Waarschuwing"
  			focusCancelBtn
  			onConfirm={() => {closefunc(); confirmfunc()}}
  			onCancel={() => {closefunc(); cancelfunc()}}
			>
  				{msg}
			</SweetAlert>;
}